import {useRoute} from "vue-router";

const computeDate = (weekday) => {
  const route = useRoute();
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  let week = route.query.week ? route.query.week : getCurrentWeek();
  week = checkWeekQuery(week);
  let d = new Date();
  let firstDayOfWeek = getFirstDayOfWeek(d.getFullYear(), week);
  let firstDay = firstDayOfWeek.getDate();
  firstDayOfWeek.setDate(firstDay + (weekday - 1));
  return firstDayOfWeek.toLocaleDateString("ru-RU", options);
};
const getFirstDayOfWeek = (year, week) => {
  // Jan 1 of 'year'
  const d = new Date(year, 0, 1),
    offset = d.getTimezoneOffset();
  // ISO: week 1 is the one with the year's first Thursday
  // so the nearest Thursday: current date + 4 - current day number
  // Sunday is converted from 0 to 7
  d.setDate(d.getDate() + 4 - (d.getDay() || 7));
  // 7 days * (week - overlapping first week)
  d.setTime(d.getTime() + 7 * 24 * 60 * 60 * 1000
    * (week + (year === d.getFullYear() ? -1 : 0 )));
  // daylight savings fix
  d.setTime(d.getTime()
    + (d.getTimezoneOffset() - offset) * 60 * 1000);
  // back to Monday (from Thursday)
  d.setDate(d.getDate() - 3);
  return d;
}
const getCurrentWeek = () => {
  Date.prototype.getWeek = function() {
    // Create a copy of the current date, we don't want to mutate the original
    const date = new Date(this.getTime());

    // Find Thursday of this week starting on Monday
    date.setDate(date.getDate() + 4 - (date.getDay() || 7));
    const thursday = date.getTime();

    // Find January 1st
    date.setMonth(0); // January
    date.setDate(1);  // 1st
    const jan1st = date.getTime();

    // Round the amount of days to compensate for daylight saving time
    const days = Math.round((thursday - jan1st) / 86400000); // 1 day = 86400000 ms
    return Math.floor(days / 7) + 1;
  }
  const d = new Date();
  return d.getWeek();
}

const getWeekNumber = (d) => {
  // Copy date so don't modify original
  d = new Date(+d);
  d.setHours(0, 0, 0, 0);
  // Set to the nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setDate(d.getDate() + 4 - (d.getDay() || 7));
  // Get first day of year
  const yearStart = new Date(d.getFullYear(), 0, 1);
  // Calculate full weeks to the nearest Thursday
  const weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7)
  // Return array of year and week number
  return [d.getFullYear(), weekNo];
}

const weeksInYear = (year) =>  {
  if(!year){
    year = new Date().getFullYear();
  }
  const month = 11;
  let day = 31;
  let week;
  // Find week that 31 Dec is in. If is first week, reduce date until
  // get previous week.
  do {
    let d = new Date(year, month, day--);
    week = getWeekNumber(d)[1];
  } while (week === 1);
  return week;
}

const checkWeekQuery = (week) => {
  if(week) week = Number(week);
  if(isNaN(week)){
    week = getCurrentWeek();
  }
  if(week && week > weeksInYear()) week = weeksInYear();
  return week;
}

const getPrevWeek = (route) => {
  let week = route.query.week ? route.query.week : getCurrentWeek();
  week = checkWeekQuery(week);
  if(week !== 1) week--;
  else week = weeksInYear();
  return week;
}
const getNextWeek = (route) => {
  let week = route.query.week ? route.query.week : getCurrentWeek();
  week = checkWeekQuery(week);
  if(week < weeksInYear()) week++;
  else week = 1;
  return week;
}

export {
  computeDate,
  getCurrentWeek,
  weeksInYear,
  checkWeekQuery,
  getPrevWeek,
  getNextWeek
};