<template>
  <div class="filter-week-buttons-block">
    <ButtonWeekFilter :to="url" />
  </div>
</template>

<script setup>

import ButtonWeekFilter from "@/features/schedule/components/ButtonWeekFilter.vue";
import {defineProps} from "vue";
import {useRoute} from "vue-router";
import {getAddress} from "@/data/helpers/address";
const props = defineProps({
  type: {
    type: Number,
    required: true,
  },
});
const route = useRoute();
const url = getAddress(route, props.type) + '?week=';
</script>

<style lang="scss" scoped>
.filter-week-buttons-block{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
}
</style>