<template>
  <template v-if="!isMobile">
    <table class="schedule-table" :class="{mobile: isMobile}">
      <thead>
      <tr>
        <th class="schedule-table__time-column">
          <ScheduleHeaderCell text="Время"/>
        </th>
        <th v-for="studyDay in studyDays" :key="studyDay" class="schedule-table__day-column" v-show="isShow(studyDay.id)">
          <ScheduleHeaderCell :text="studyDay.name" :date="computeDate(studyDay.id)"/>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="bell in bells" :key="bell.id">
        <td>
          <ScheduleHeaderCell :text="bell.begin"/>
        </td>
        <td v-for="studyDay in studyDays"
            :key="studyDay"
            v-show="isShow(studyDay.id)"
            class="schedule-table__cell"
            :class="getCellCssClass(schedule[computeIndex(studyDay.id, bell.begin)])"
        >
          <template v-if="schedule[computeIndex(studyDay.id, bell.begin)]">
            <template v-if="Array.isArray(schedule[computeIndex(studyDay.id, bell.begin)])">
              <template v-for="scheduleItem in schedule[computeIndex(studyDay.id, bell.begin)]" :key="scheduleItem.id">
                <ScheduleCell
                  :lesson="scheduleItem.lesson"
                  :date="scheduleItem.date"
                  :classroom="type !== SCHEDULE_TYPE_CLASSROOM ? scheduleItem.classroom : null"
                  :group="type === SCHEDULE_TYPE_CLASS ? getSubgroup(scheduleItem.schoolClasses) : null"
                  :teacher="type !== SCHEDULE_TYPE_TEACHER ? scheduleItem.teacher : null"
                  :schoolClasses="type !== SCHEDULE_TYPE_CLASS ? scheduleItem.schoolClasses : null"/>
              </template>
            </template>
            <template v-else>
              <ScheduleCell
                :lesson="schedule[computeIndex(studyDay.id, bell.begin)].lesson"
                :date="schedule[computeIndex(studyDay.id, bell.begin)].date"
                :classroom="type !== SCHEDULE_TYPE_CLASSROOM ? schedule[computeIndex(studyDay.id, bell.begin)].classroom: null"
                :group="type === SCHEDULE_TYPE_CLASS ? getSubgroup(schedule[computeIndex(studyDay.id, bell.begin)].schoolClasses) : null"
                :teacher="type !== SCHEDULE_TYPE_TEACHER ? schedule[computeIndex(studyDay.id, bell.begin)].teacher : null"
                :schoolClasses="type !== SCHEDULE_TYPE_CLASS ? schedule[computeIndex(studyDay.id, bell.begin)].schoolClasses : null"/>
            </template>
          </template>
          <template v-else>
            <template v-if="canReserve">
              <div class="schedule-cell-reserve">
                <LinkItem
                    to="#"
                    text="Забронировать"
                    @click="handleClick(studyDay.name, bell.begin)"
                />
              </div>
            </template>
          </template>
        </td>
      </tr>
      </tbody>
    </table>
  </template>
  <template v-else>
    <ScheduleCarousel
        :studyDays="studyDays"
        :bells="bells"
        :schedule="schedule"
        :isMobile="isMobile"
        :canReserve="canReserve"
        :type="type"
    />
  </template>
  <ReserveModal :studyDay="studyDay" :bell="bell" :modal="modal"/>
</template>

<script setup>
import {defineProps, ref} from "vue";
import {useRoute} from "vue-router";
import {SCHEDULE_TYPE_TEACHER, SCHEDULE_TYPE_CLASS, SCHEDULE_TYPE_CLASSROOM} from "@/data/constants";
import ScheduleCell from "@/features/schedule/components/ScheduleCell.vue";
import ScheduleHeaderCell from "@/features/schedule/components/ScheduleHeaderCell.vue";
import LinkItem from "@/components/LinkItem.vue";
import {Modal} from "bootstrap";
import ReserveModal from "@/components/ReserveModal.vue";
import ScheduleCarousel from "@/features/carousel/components/ScheduleCarousel.vue";
import {computeDate} from "@/data/helpers/dates";


const route = useRoute();
const {schoolClassName} = route.params;
const modal = ref();
const studyDay = ref();
const bell = ref();

const props = defineProps({
  isMobile: {
    type: Boolean,
    default: () => false,
  },
  type: {
    type: Number,
    required: true,
  },
  studyDays: {
    type: Array,
    default: () => [],
  },
  bells: {
    type: Array,
    default: () => [],
  },
  schedule: {
    type: Array,
    default: () => [],
  },
  canReserve: Boolean,
});

const handleClick = (studyDayCell, bellCell) => {
  studyDay.value = studyDayCell;
  bell.value = bellCell;
  modal.value = new Modal(document.getElementById('reserveModal'), {});
  modal.value.show();
}
const getSubgroup = (groups) => {
  if (!schoolClassName) return null;
  let subGroups = groups.filter((group) => group.name = schoolClassName);
  return (undefined !== subGroups[0]) ? subGroups[0] : null;
}

const getCellCssClass = (items) => {
  if (!items || !Array.isArray(items)) return 'empty';
  if (items.length < 2) return '';
  return 'schedule-table__cell-list';
}

/**
 * Преобразование индекса двумерного массива к одномерному
 * @param weekday
 * @param time
 * @returns {*}
 */
const computeIndex = (weekday, time) => {
  return weekday + '-' + time;
};


const isShow = (weekday) => {
  return !props.isMobile || weekday === getSelectDay()
}

const getSelectDay = () => {
  let day = parseInt(route.query.day);
  if (!day) {
    let d = new Date();
    return [1, 1, 2, 3, 4, 5, 6][d.getDay()];
  }
  return day;
}


</script>

<style lang="scss" scoped>
.schedule-table {
  width: 100%;

  .schedule-table__time-column {
    width: 10%;
  }

  .schedule-table__day-column {
    width: 15%;
  }

  thead {
    tr {
      background-color: #E8EDF3;
    }

    th {
      border: 1px solid #FFFFFF;
    }
  }

  tbody {
    td {
      border: 1px solid #E8EDF3;

      &.schedule-table__cell {
        vertical-align: top;
      }

      &.schedule-table__cell-list > * {
        &:nth-child(odd) {
          //background-color: #F4EEEB;
        }
        &:nth-child(even) {
          //background-color: #E9F3FA;
        }
      }
    }
  }
}

.empty{
  transition: all .1s linear;
  .schedule-cell-reserve{
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 10px;
    padding: 10px 10px 20px;
    .link-item{
      font-size: .75rem;
      padding:10px 0;
      width: 100%;
    }
  }
  &:hover{
    .schedule-cell-reserve{
      display: flex;
    }
  }
}
</style>