<template>
  <PreloaderSpinner v-if="loading"/>
  <div class="filter-week-buttons-block">
    <span @click="handlePrevClick()">
      <i class="icon icon-chevron prev"></i>
    </span>
    <span @click="handleNextClick()">
      <i class="icon icon-chevron next"></i>
    </span>
  </div>
  <ul class="filter-items tabs">
    <li v-for="(studyDay, index) in studyDaysShort"
        :key="studyDay"
        class="tab"
        @click="handleFilterClick(index)"
    >
      <ButtonSimple
          :text="studyDay.name"
          :active="studyDay.id === getSelectDay()"
      />
    </li>
  </ul>
  <Carousel
      v-bind="config"
      ref="myCarousel"
      @slide-end="handleSlideEnd"
      @slide-start="handleSlideStart"
  >
    <Slide v-for="studyDay in studyDays" :key="studyDay">
      <table class="schedule-table mobile">
        <thead>
        <tr>
          <th class="schedule-table__time-column">
            <ScheduleHeaderCell text="Время"/>
          </th>
          <th class="schedule-table__day-column" >
            <ScheduleHeaderCell :text="studyDay.name" :date="computeDate(studyDay.id)"/>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="bell in bells" :key="bell.id">
          <td>
            <ScheduleHeaderCell :text="bell.begin"/>
          </td>
          <td class="schedule-table__cell"
              :class="getCellCssClass(schedule[computeIndex(studyDay.id, bell.begin)])"
          >
            <ScheduleTdInner
                v-if="schedule[computeIndex(studyDay.id, bell.begin)]"
                :schedule="schedule"
                :weekday="studyDay.id"
                :time="bell.begin"
                :type="type"
                :canReserve="canReserve"
            />
            <template v-else>
              <template v-if="canReserve">
                <div class="schedule-cell-reserve">
                  <LinkItem
                      to="#"
                      text="Забронировать"
                      @click="handleClick(studyDay.name, bell.begin)"
                  />
                </div>
              </template>
            </template>
          </td>
        </tr>
        </tbody>
      </table>
    </Slide>
  </Carousel>
  <ReserveModalMobile :studyDay="studyDay" :bell="bell" :modal="modal"/>
</template>

<script setup>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'
import {defineProps, onMounted, ref} from "vue";
import ScheduleHeaderCell from "@/features/schedule/components/ScheduleHeaderCell.vue";
import {useRoute, useRouter} from "vue-router";
import {studyDaysShort} from "@/data/days";
import ButtonSimple from "@/components/ButtonSimple.vue";
import ReserveModalMobile from "@/components/ReserveModalMobile.vue";
import {checkWeekQuery, computeDate, getNextWeek, getPrevWeek} from "@/data/helpers/dates";
import {api} from "@/services/backend";
import PreloaderSpinner from "@/components/PreloaderSpinner.vue";
import ScheduleTdInner from "@/features/schedule/components/ScheduleTdInner.vue";
import {Modal} from "bootstrap";
import LinkItem from "@/components/LinkItem.vue";
import {getAddress} from "@/data/helpers/address";

const route = useRoute();
const router = useRouter();
const {schoolClassName} = route.params;
const {classroomName} = route.params;
const {teacherName} = route.params;
const myCarousel = ref(null);
const currentSlideIndex = ref();
const modal = ref();
const studyDay = ref();
const bell = ref();
const bells = ref([]);
const currentDayIndex = ref();
const loading = ref(false);
const schedule = ref([]);
const cssClass = ref();

const props = defineProps({
  studyDays: {
    type: Array,
    default: () => [],
  },
  isMobile: {
    type: Boolean,
    default: true,
  },
  type: {
    type: Number,
    required: true,
  },
  canReserve: Boolean
});

const config = {
  wrapAround: true,
  modelValue: currentDayIndex
};

const handlePrevClick = () => {
  console.log('prev week');
  getSchedulePrevWeek();
}

const handleNextClick = () => {
  console.log('next week');
  getScheduleNextWeek();
}

const handleSlideStart = (data) => {
  const to = data.slidingToIndex;
  if(to === 6){
    getScheduleNextWeek();
  }else if(to === -1){
    getSchedulePrevWeek();
  }
}
const getScheduleNextWeek = async () => {
  loading.value = true;
  const week = getNextWeek(route);
  try {
    const response = await api.schedule.find(
        new URLSearchParams({
          group: schoolClassName ? schoolClassName : '',
          teacher: teacherName ? teacherName : '',
          classroom: classroomName ? classroomName : '',
          week: week,
        })
    );
    schedule.value = response.schedule;
    await router.push({
      path: getAddress(route, props.type),
      params: {
        userId: 2
      },
      query: {
        ...route.query,
        week: week
      }
    });
    loading.value = false;
  } catch (error) {
    console.error('error', error);
  }

}
const getSchedulePrevWeek = async () => {
  loading.value = true;
  const week = getPrevWeek(route);
  try {
    const response = await api.schedule.find(
        new URLSearchParams({
          group: schoolClassName ? schoolClassName : '',
          teacher: teacherName ? teacherName : '',
          classroom: classroomName ? classroomName : '',
          week: week,
        })
    );
    schedule.value = response.schedule;
    await router.push({
      path: getAddress(route, props.type),
      params: {},
      query: {
        ...route.query,
        week: week
      }
    });
    loading.value = false;
  } catch (error) {
    console.error('error', error);
  }
}

const getCellCssClass = (items) => {
  if (!items || !Array.isArray(items)) return 'empty';
  if (items.length < 2) return '';
  return 'schedule-table__cell-list';
}
const computeIndex = (weekday, time) => {
  return weekday + '-' + time;
};
const handleSlideEnd = (data) => {
  currentSlideIndex.value = data.currentSlideIndex;
  console.log(currentSlideIndex.value);
}

const handleClick = (studyDayCell, bellCell) => {
  studyDay.value = studyDayCell;
  bell.value = bellCell;
  modal.value = new Modal(document.getElementById('reserveModalMobile'), {});
  modal.value.show();
}
const handleFilterClick = (index) => {
  myCarousel.value.slideTo(index);
}

const getSelectDay = () => {
  let day = currentSlideIndex.value + 1;
  if (!day) {
    let d = new Date();
    return [1, 1, 2, 3, 4, 5, 6][d.getDay()];
  }
  return day;
}
const getBells = async () => {
  loading.value = true;
  try {
    const response = await api.bell.all();
    bells.value = response.bells;
    loading.value = false;
  } catch (error) {
    console.error('error', error);
  }
}
const getSchedule = async () => {
  loading.value = true;
  const route = useRoute();
  let week = route.query.week ? route.query.week : '';
  week = checkWeekQuery(week);
  try {
    const response = await api.schedule.find(
        new URLSearchParams({
          group: schoolClassName ? schoolClassName : '',
          teacher: teacherName ? teacherName : '',
          classroom: classroomName ? classroomName : '',
          week: week,
        })
    );
    schedule.value = response.schedule;
    cssClass.value = 'schedule-table__cell-list';
    loading.value = false;
  } catch (error) {
    console.error('error', error);
  }
}
onMounted( () => {
  console.log(currentDayIndex.value);
  currentDayIndex.value = getSelectDay() - 1;
  console.log(currentDayIndex.value);
  getBells();
  getSchedule();

});

</script>

<style lang="scss" scoped>
.carousel{
  .carousel__slide{
    align-items: flex-start;
    text-align: left;
  }
}
.schedule-table {
  width: 100%;
  .schedule-table__time-column {
    width: 10%;
  }
  .schedule-table__day-column {
    width: 15%;
  }
  thead {
    tr {
      background-color: #E8EDF3;
    }

    th {
      border: 1px solid #FFFFFF;
    }
  }
  tbody {
    td {
      border: 1px solid #E8EDF3;
      &.schedule-table__cell {
        vertical-align: top;
      }
    }
  }
  &.mobile {
    .schedule-table__time-column {
      width: 20%;
    }
    .schedule-table__day-column {
      width: 80%;
    }
    .empty{
      transition: all .1s linear;
      .schedule-cell-reserve{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        gap: 10px;
        padding: 10px 10px 20px;
        .link-item{
          font-size: .75rem;
          padding:10px 0;
          width: 100%;
        }
      }
    }
    .schedule-cell{
      .schedule-cell__content{
        align-items: flex-start;
      }
    }
  }
}
.tabs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
  padding: 0
}
.filter-items{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 60px;
  overflow: hidden;
  height: auto
}
.filter-week-buttons-block{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
  span{
    &:hover{
      cursor: pointer;
    }
  }
  i{
    &.icon{
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      &.icon-chevron{
        background-size: cover;
        background-repeat: no-repeat;
        &.prev{
          background-image: url('../assets/chevron-left.svg');
        }
        &.next{
          background-image: url('../assets/chevron-right.svg');
        }
      }
    }
  }

}
</style>