import {
  SCHEDULE_TYPE_CLASS,
  SCHEDULE_TYPE_CLASSROOM, SCHEDULE_TYPE_TEACHER,
  URL_CLASSES,
  URL_CLASSROOMS,
  URL_TEACHERS
} from "@/data/constants";

const getAddress = (route, type) => {
  const {schoolClassName} = route.params;
  const {classroomName} = route.params;
  const {teacherName} = route.params;
  let url = URL_CLASSES + '/';
  if(type === SCHEDULE_TYPE_CLASS){
    url = URL_CLASSES + '/' + schoolClassName;
  }else if(type === SCHEDULE_TYPE_CLASSROOM){
    url = URL_CLASSROOMS + '/' + classroomName;
  }else if(type === SCHEDULE_TYPE_TEACHER){
    url = URL_TEACHERS + '/' + teacherName;
  }
  return url;
}

export {
  getAddress

}