<template>
      <template v-if="Array.isArray(schedule[computeIndex(weekday, time)])">
        <template v-for="scheduleItem in schedule[computeIndex(weekday, time)]" :key="scheduleItem.id">
          <ScheduleCell
              :lesson="scheduleItem.lesson"
              :date="scheduleItem.date"
              :classroom="type !== SCHEDULE_TYPE_CLASSROOM ? scheduleItem.classroom : null"
              :group="type === SCHEDULE_TYPE_CLASS ? getSubgroup(scheduleItem.schoolClasses) : null"
              :teacher="type !== SCHEDULE_TYPE_TEACHER ? scheduleItem.teacher : null"
              :schoolClasses="type !== SCHEDULE_TYPE_CLASS ? scheduleItem.schoolClasses : null"/>
        </template>
      </template>
      <template v-else>
        <ScheduleCell
            :lesson="schedule[computeIndex(weekday, time)].lesson"
            :classroom="type !== SCHEDULE_TYPE_CLASSROOM ? schedule[computeIndex(studyDay.id, bell.begin)].classroom: null"
            :group="type === SCHEDULE_TYPE_CLASS ? getSubgroup(schedule[computeIndex(studyDay.id, bell.begin)].schoolClasses) : null"
            :teacher="type !== SCHEDULE_TYPE_TEACHER ? schedule[computeIndex(studyDay.id, bell.begin)].teacher : null"
            :schoolClasses="type !== SCHEDULE_TYPE_CLASS ? schedule[computeIndex(studyDay.id, bell.begin)].schoolClasses : null"/>
      </template>
</template>

<script setup>
import {defineProps, ref} from "vue";
import {SCHEDULE_TYPE_CLASS, SCHEDULE_TYPE_CLASSROOM, SCHEDULE_TYPE_TEACHER} from "@/data/constants";
import {useRoute} from "vue-router";
import ScheduleCell from "@/features/schedule/components/ScheduleCell.vue";


const route = useRoute();
const {schoolClassName} = route.params;
const studyDay = ref();
const bell = ref();

defineProps({
  schedule: {
    type: Array,
    default: () => [],
  },
  weekday: {
    type: Number,
    required: true,
  },
  time: {
    type: String,
    required: true,
  },
  type: {
    type: Number,
    required: true,
  },
  canReserve: Boolean
});

const computeIndex = (weekday, time) => {
  return weekday + '-' + time;
};

const getSubgroup = (groups) => {
  if (!schoolClassName) return null;
  let subGroups = groups.filter((group) => group.name = schoolClassName);
  return (undefined !== subGroups[0]) ? subGroups[0] : null;
}

</script>

<style lang="scss" scoped>

</style>