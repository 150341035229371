<template >
  <a :href="prevWeek" @click="handlePrevClick()">
    <i class="icon icon-chevron prev"></i>
  </a>
  <a :href="nextWeek" @click="handleNextClick()">
    <i class="icon icon-chevron next"></i>
  </a>
</template>

<script setup>

import {defineProps, onMounted, ref} from "vue";
import {getNextWeek, getPrevWeek} from "@/data/helpers/dates";
import {useRoute} from "vue-router";

const route = useRoute();
const prevWeek = ref();
const nextWeek = ref();

const props = defineProps({
  to: {
    type: String,
    required: true,
  },
});


const handlePrevClick = () => {
  const week = getPrevWeek(route);
  prevWeek.value = props.to + week;
}

const handleNextClick = () => {
  const week = getNextWeek(route);
  nextWeek.value = props.to + week;
}

onMounted( () => {
  prevWeek.value =  props.to + getPrevWeek(route);
  nextWeek.value =  props.to + getNextWeek(route);
});
</script>

<style lang="scss" scoped>
i{
  &.icon{
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.icon-chevron{
      background-size: cover;
      background-repeat: no-repeat;
      &.prev{
        background-image: url('../assets/chevron-left.svg');
      }
      &.next{
        background-image: url('../assets/chevron-right.svg');
      }
    }
  }
}
</style>